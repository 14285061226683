<template>
  <div class="readme-article">
    <h1 id="回访">二、回访</h1>
    <h2 id="预约池">1.预约池</h2>
    <p>查看有预约下次联系时间的线索列表。</p>
    <p><img src="@/assets/img/call/2-1.png" /></p>
    <h2 id="回访跟踪管理">2.回访跟踪管理</h2>
    <p>查看回访记录，显示回访时间，回访人姓名，意向状态，内容等信息。</p>
    <p><img src="@/assets/img/call/2-2.png" /></p>
    <h2 id="线索电话记录">3.线索电话记录</h2>
    <p>查看拨打电话记录，显示客户姓名、主叫号码、被叫号码、业务状元、通话时长等信息，支持数据导出。</p>
    <p><img src="@/assets/img/call/2-3.png" /></p>
    <!-- <h2 id="主管首页">4.主管首页</h2>
    <p>查看员工日、周、月的业绩、新增线索、回访人数、呼叫时长等信息。</p>
    <p><img src="@/assets/img/call/2-4.png" /></p> -->
  </div>
</template>

<script>
export default {
  name: "call2-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>